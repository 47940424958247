.d-content-dark {
  background-color: #2e2e2e;
}

.d-gray {
  color: #173039
}

.d-content-highlight {
  background-color: #0cc1cb !important;
}

.d-content-center {
  text-align: center;
}

.d-content-round-border {
  border-radius: 8px;
  border: solid 1px #0cc1cb;
  padding: 5%;
}

.btn-sell-token{
  background-color: #10d3c0 !important;
  color: #ffffff !important;
}
.d-black {
  color: black;
}

.d-white {
  color: var(--text-color) !important;
  /* color: white !important; */
}

.d-highlight {
  color: #0cc1cb;
}

.d-font-bold {
  font-family: "Circular Std Bold" !important;
}

.d-font-book {
  font-family: "Circular Std Book";
}

.d-font-black {
  font-family: "Circular Std Black";
}

.d-font-strong {
  font-weight: bold !important;
}
.d-font-mont-bold {
  font-family: "Montserrat-Bold";
}
.d-font-mont-regular {
  font-family: "Montserrat-Regular";
}

/*  ================font definition================================   */
.d-text-header {
  font-size: 92px !important;
}

.d-text-90 {
  font-size: 90px !important;
  line-height: 80px;
}

.d-text-85 {
  font-size: 85px !important;
}

.d-text-82 {
  font-size: 82px !important;
}

.d-text-72 {
  font-size: 72px !important;
}

.d-text-60 {
  font-size: 60px !important;
}

.d-text-56 {
  font-size: 56px !important;
}

.d-text-48 {
  font-size: 48px !important;
  line-height: 40px;
}
.d-text-40 {
  font-size: 40px !important;
  line-height: 40px;
}

.d-text-36 {
  font-size: 36px !important;
}
.d-text-32 {
  font-size: 32px !important;
}

.d-text-30 {
  font-size: 30px !important;
}

.d-text-28 {
  font-size: 28px !important;
}

.d-text-24 {
  font-size: 24px !important;
}

.d-text-20 {
  font-size: 20px !important;
}

.d-text-18 {
  font-size: 18px !important;
}

.d-text-nav {
  font-size: 18px !important;
}

.d-text-14 {
  font-size: 14px !important;
}

.d-text-10 {
  font-size: 10px !important;
}

.d-text-100 {
  font-size: 100px !important;
}
.tokized_start {
  width: 13vw;
}

.affilit-position {
  margin-left: 20%
}

/* .btn-white {
  background-color: white !important:
} */

.invest_size {
  width: 170px;
  height: 30px;
  font-weight: 1000;
  color: #1e8ea5;
  background-color: #00f6ff;
  border-radius: 9px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: -3px;
  margin-top: -5px
}

/* @media only screen and (max-width: 991px) {
  .invest_size
} */
/*  ================font definition according to devices================   */
@media only screen and (max-width: 991px) {
  .invest_size {
    width: 100px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column
  }
}

@media only screen and (max-width: 612px) {

  .d-text-header {
    font-size: 36px !important;
  }

  .d-text-90 {
    font-size: 36px !important;
    line-height: 36px;
  }

  .d-text-100 {
    font-size: 40px !important;
  }
  .d-text-87 {
    font-size: 33px !important;
  }
  .d-text-85 {
    font-size: 30px !important;
    line-height: 30px;
  }

  .d-text-82 {
    font-size: 29px !important;
  }

  .d-text-72 {
    font-size: 25px !important;
  }

  .d-text-60 {
    font-size: 21px !important;
  }
  .d-text-50 {
    font-size: 50px !important;
  }
  .d-text-56 {
    font-size: 24px !important;
  }

  .d-text-48 {
    font-size: 17px !important;
    line-height: 17px;
  }

  .d-text-36 {
    font-size: 13px !important;
  }
  .d-text-40 {
    font-size: 15px !important;
    line-height: 15px;
  }
  .d-text-32 {
    font-size: 12px !important;
  }

  .d-text-30 {
    font-size: 12px !important;
  }

  .d-text-28 {
    font-size: 12px !important;
  }

  .d-text-24 {
    font-size: 12px !important;
  }

  .d-text-20 {
    font-size: 12px !important;
  }

  .d-text-18 {
    font-size: 12px !important;
  }

  .d-text-nav {
    font-size: 12px !important;
  }

  .d-text-14 {
    font-size: 12px !important;
  }

  .d-text-10 {
    font-size: 12px !important;
  }

  .img-mobile {
    zoom: 50%;
  }
  .tokized_start {
    width: 35vw;
  }
}

@media only screen and (min-width: 613px) and (max-width: 887px) {
  .d-text-header {
    font-size: 42px !important;
  }

  .d-text-90 {
    font-size: 42px !important;
    line-height: 42px;
  }

  .d-text-100 {
    font-size: 50px !important;
  }
  .d-text-87 {
    font-size: 40px !important;
  }
  .d-text-85 {
    font-size: 37px !important;
    line-height: 37px;
  }

  .d-text-82 {
    font-size: 36px !important;
  }

  .d-text-72 {
    font-size: 32px !important;
  }

  .d-text-60 {
    font-size: 26px !important;
  }

  .d-text-56 {
    font-size: 28px !important;
  }
  .d-text-50 {
    font-size: 15px !important;
  }
  .d-text-48 {
    font-size: 21px !important;
    line-height: 21px;
  }

  .d-text-36 {
    font-size: 16px !important;
  }
  .d-text-40 {
    font-size: 18px !important;
    line-height: 18px;
  }
  .d-text-32 {
    font-size: 14px !important;
  }

  .d-text-30 {
    font-size: 13px !important;
  }

  .d-text-28 {
    font-size: 12px !important;
  }

  .d-text-24 {
    font-size: 12px !important;
  }

  .d-text-20 {
    font-size: 12px !important;
  }

  .d-text-18 {
    font-size: 12px !important;
  }

  .d-text-14 {
    font-size: 12px !important;
  }

  .d-text-10 {
    font-size: 12px !important;
  }

  .img-mobile {
    zoom: 70%;
  }
}

@media only screen and (min-width: 888px) and (max-width: 946px) {
  .d-text-header {
    font-size: 50px !important;
  }

  .d-text-90 {
    font-size: 50px !important;
    line-height: 50px;
  }

  .d-text-100 {
    font-size: 65px !important;
  }
  .d-text-87 {
    font-size: 45px !important;
  }
  .d-text-85 {
    font-size: 42px !important;
    line-height: 42px;
  }

  .d-text-82 {
    font-size: 41px !important;
  }

  .d-text-72 {
    font-size: 36px !important;
  }

  .d-text-60 {
    font-size: 30px !important;
  }

  .d-text-56 {
    font-size: 30px !important;
  }
  .d-text-50 {
    font-size: 25px !important;
  }
  .d-text-48 {
    font-size: 24px !important;
    line-height: 24px;
  }

  .d-text-36 {
    font-size: 18px !important;
  }

  .d-text-32 {
    font-size: 16px !important;
  }

  .d-text-30 {
    font-size: 15px !important;
  }

  .d-text-28 {
    font-size: 14px !important;
  }

  .d-text-24 {
    font-size: 12px !important;
  }

  .d-text-20 {
    font-size: 12px !important;
  }

  .d-text-18 {
    font-size: 12px !important;
  }

  .d-text-14 {
    font-size: 12px !important;
  }

  .d-text-10 {
    font-size: 10px !important;
  }
  .img-mobile {
    zoom: 90%;
  }
}

.card-contact {
  font-family:"Montserrat-Bold";
  background-image: linear-gradient(to top right, #08b2d3, #11d5bf);
}

@media only screen and (min-width: 947px) and (max-width: 1472px) {
  .d-text-header {
    font-size: 60px !important;
  }

  .d-text-90 {
    font-size: 60px !important;
    line-height: 60px;
  }
  .d-text-100 {
    font-size: 80px !important;
  }
  .d-text-87 {
    font-size: 52px !important;
  }
  .d-text-85 {
    font-size: 45px !important;
    line-height: 45px;
  }

  .d-text-82 {
    font-size: 41px !important;
  }

  .d-text-72 {
    font-size: 36px !important;
  }

  .d-text-60 {
    font-size: 30px !important;
  }

  .d-text-56 {
    font-size: 32px !important;
  }

  .d-text-48 {
    font-size: 24px !important;
    line-height: 24px;
  }
  .d-text-40 {
    font-size: 20px !important;
    line-height: 24px;
  }
  .d-text-36 {
    font-size: 18px !important;
  }

  .d-text-32 {
    font-size: 16px !important;
  }

  .d-text-30 {
    font-size: 15px !important;
  }

  .d-text-28 {
    font-size: 14px !important;
  }

  .d-text-24 {
    font-size: 12px !important;
  }

  .d-text-20 {
    font-size: 12px !important;
  }

  .d-text-18 {
    font-size: 12px !important;
  }

  .d-text-14 {
    font-size: 12px !important;
  }

  .d-text-10 {
    font-size: 10px !important;
  }
  .d-text-nav {
    font-size: 10px !important;
  }
  .d-text-50 {
    font-size: 24px !important;
  }
  .img-mobile {
    zoom: 90%;
  }
}

.back-transparent {
  background: transparent !important;
}

@media only screen and (min-width: 1216px) and (max-width: 3500px){
  .top-space {
    margin-top: -120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1216px){
  .top-space {
    margin-top: -150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px){
  .top-space {
    margin-top: -250px;
  }
}

@media only screen and (max-width: 768px){
  .top-space {
    margin-top: -110px;
  }
}


@media only screen and (min-width: 1473px) and (max-width: 3500px) {
  .d-text-header {
    font-size: 90px !important;
  }

  .d-text-90 {
    font-size: 85px !important;
    line-height: 85px;
  }

  .d-text-100 {
    font-size: 90px !important;
  }
  .d-text-87 {
    font-size: 70px !important;
  }
  .d-text-85 {
    font-size: 60px !important;
    line-height: 60px;
  }

  .d-text-82 {
    font-size: 54px !important;
  }

  .d-text-72 {
    font-size: 48px !important;
  }

  .d-text-60 {
    font-size: 40px !important;
  }

  .d-text-56 {
    font-size: 42px !important;
  }
  .d-text-50 {
    font-size: 35px !important;
  }
  .d-text-48 {
    font-size: 32px !important;
    line-height: 32px;
  }

  .d-text-36 {
    font-size: 24px !important;
  }
  .d-text-40 {
    font-size: 28px !important;
    line-height: 28px;
  }

  .d-text-32 {
    font-size: 21px !important;
  }

  .d-text-30 {
    font-size: 20px !important;
  }

  .d-text-28 {
    font-size: 18px !important;
  }

  .d-text-24 {
    font-size: 100% !important;
  }

  .d-text-20 {
    font-size: 16px !important;
  }

  .d-text-18 {
    font-size: 18px !important;
  }

  .d-text-14 {
    font-size: 12px !important;
  }

  .d-text-10 {
    font-size: 12px !important;
  }
  .d-text-nav {
    font-size: 15px !important;
  }
  .img-mobile {
    zoom: 90%;
  }
}

.img-box {
  display: table;
  width: 100%;
  padding: 0;
  background-color: transparent;
}

.img-box-access {
  background: url('imgs/image/get_access/1Get_access4.png');
  background-size: cover;
}

.img-box-privacy {
  background: url('imgs/image/privacy/background_1.png');
  background-size: cover;
}

.img-box-generll {
  background: url("imgs/image/general_info/background_2.png");
}

.img-box-cooki {
  background: url("imgs/image/cooki/background.png");
  background-size: cover;
}
.img-box-head{
  background: url("imgs/image/header_background.png");
  height: 236px;
}
.position{
  position: fixed;
}
.img-box-team {
  background: url("imgs/image/Team/top.png") no-repeat center;
  background-size: cover;
}
.img-box-sell-tokens {
  background: url("imgs/image/sell_token/first.png") no-repeat center;
  background-size: cover;
}

.img-box-learn {
  background: url("imgs/image/learn/background.png");
  background-size: cover;
}

.d-color-blue {
  color: #10d3c0 !important;
}

.work-title {
  margin-top: 7%;
  border-bottom: 4px solid #10d3c0;
}

.img-box-how-it-works {
  background: url("imgs/image/how_it_wokrs/background_1.png") no-repeat center;
  background-size: cover;
}
.img-box-myaccount {
  background: url("imgs/myaccount.png") no-repeat center;
  background-size: cover;
}
.img-box-faqs {
  /* height: 475px; */
  background: url("imgs/faq.png") no-repeat center;
  background-size: cover;
}

.center-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
}


@media only screen and (max-width: 767px) {
  .image-display {
    display: none;
  }
}

.img-box-faq {
  height: 475px;
  background: url("imgs/image/faq/background.png") no-repeat center;
  background-size: cover;
}

.img-box-token {
  background: url("imgs/image/sell_token/secode.png") no-repeat center;
  background-size: cover;
}
.btn-primary {
  color: #fff;
  background-color: #ffffff;
  border: 1px solid transparent !important;
  border-radius: 8px !important;
}

.btn-access {
  background-color: #ffffff !important;
  color: #05cfd8 !important
}

.btn-borrow{
  color: #00f6ff !important;
  background-color: #0c1720 !important;
  border: 1px solid transparent !important;
  border-radius: 8px !important;  
  margin-left: 15% !important;
}

.vertical-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.right-border {
  padding-right: 2%;
  border-right: 6px solid #10d3c0;
}

.border-style1 {
  border: 6px solid #173039;
  border-radius: 10px
}

.border-style2 {
  border: 6px solid #f58420;
  border-radius: 10px;
}

.left-border {
  padding-left: 2%;
  border-left: 6px solid #10d3c0;
}

.text-30 {
  font-size: 30px;
}

.color-black {
  color: #173039;
}

.btn-blue {
  background-color: #10d3c0 !important;
}

.d-content-blue {
  background-color: #10d3c0;
}

.btn-green {
  background-color: #00f6ff !important;
}

.text-black {
  color: #173039
}

.text-blue {
  color: #10d3c0 !important;
}

.img-box-market {
  background: url("imgs/image/market_place/background.png");
  background-size: cover;
}

.bg-blue {
  background-color: #10d3c0 !important;
}
.text-bold {
  font-weight: bold;
}

.d-font-circular {
  font-family: Arial !important
}

.button-center {
  margin-left: 40%;
}

.btn-borrow1{
  color: #ffffff !important;
  background-color: #0c1720 !important;
  border: 1px solid transparent !important;
  border-radius: 8px !important;  
  margin-left: 15% !important;
}

.gradient{
  background-image: linear-gradient(to right, #00b4c9, #00e1c8);
}

.color-blue {
  color: #11d5bf;
}

.btn-affilit {
  color: #173039 !important;
  background-color: #00f6fe !important;
}
.btn-contact{
  background-color: #173039 !important;
}
.btn-second {
  color: #dba87e;
  background-color: #fff !important;

  box-shadow: 4.7px 9.14px 9px #00000026;
  border-radius: 8px !important;
  height: 50px;
}

.d-btn-radius-2 {
  border-radius: 2px !important;
}

.d-black-highlight-button {
  background-color: black !important;
  color: #dba87e !important;
}

.d-black-white-button {
  background-color: black !important;
  color: white !important;
}

.d-none-white-button {
  background-color: transparent !important;
  color: white !important;
  border-width: 2px !important;
  border-color: white !important;
}
.secondary-btn {
  background-color: white !important;
  color: #33546e !important;
}
.primary-btn {
  background-color: #00f6ff !important;
  color: #0c1720 !important;
}


@media only screen and (max-width: 1750px){
  .margin_5 {
    margin-left: 0;
  }
}


@media only screen and (min-width: 1750px) and (max-width: 1800px){
  .margin_5 {
    margin-left: 2%;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 1850px){
  .margin_5 {
    margin-left: 4%;
  }
}

@media only screen and (min-width: 1850px) and (max-width: 1920px){
  .margin_5 {
    margin-left: 6%;
  }
}

@media only screen and (min-width: 1920px) {
  .margin_5 {
    margin-left: 7%;
  }
}



@media only screen and (min-width: 1600px) and (max-width: 1920px) {
  .margin-80 {
    margin-bottom: -80px
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .margin-80 {
    margin-bottom: -50px
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .margin-80 {
    margin-bottom: -40px
  }
}

@media only screen and (min-width: 600px) and (max-width: 1204px) {
  .margin-80 {
    margin-bottom: -30px
  }
}

@media only screen and (max-width: 600px) {
  .margin-80 {
    margin-bottom: -20px
  }
}

@media all and (min-width: 991.98px) {
  .deskContent {
    display: flex !important;
  }

  .phoneContent {
    display: none !important;
  }

  .color-transparent {
    background: transparent !important;
  }
}

@media all and (max-width: 991.98px) {
  .deskContent {
    display: none !important;
  }

  .phoneContent {
    display: block !important;
  }
  .color-transparent {
    background-color: black !important;
  }
}

@media all and (min-width: 1280px) {
  .product-deskContent {
    display: block !important;
  }

  .product-phoneContent {
    display: none !important;
  }
}

@media all and (max-width: 612px) {
  .product-deskContent {
    display: none !important;
  }

  .product-phoneContent {
    display: block !important;
  }
}

@media all and (min-width: 450px) {
  .footer-deskContent {
    display: flex !important;
  }

  .footer-deskContent-link-left {
    margin-left: 50%;
    width: 95%;
  }

  .footer-phoneContent {
    display: none !important;
  }
}

@media all and (max-width: 900px) {
  .footer-deskContent {
    display: none !important;
  }

  .footer-phoneContent {
    display: block !important;
  }
}

/*--------get access page-------------*/
.get-access-div {
  text-align: center;
  margin-top: 1%;
  margin-bottom: 5%;
}

.get-access-div img {
  width: 100px;
}

.get-access-side {
  margin-left: 14%;
  margin-right: 17%;
}

.get-access-invitation {
  border: 1px solid;
  border-radius: 5px;
  color: white;
  margin-right: 1vw;
  padding: 5px;
  font-size: 14px;
  width: 16.5vw;
}
.get-access-invitation::placeholder {
  color: white;
}
@media only screen and (min-width: 400px) and (max-width: 800px) {
  .get-access-logo {
    width: 100px !important;
  }

  .get-access-title {
    font-size: 36px;
    line-height: 28px;
    margin-top: 170px !important;
  }

  .get-access-content {
    font-size: 16px;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1367px) {
  .get-access-logo {
    width: 150px !important;
  }

  .get-access-title {
    font-size: 50px;
    line-height: 42px;
    margin-top: 140px !important;
  }

  .get-access-content {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1368px) and (max-width: 3500px) {
  .get-access-logo {
    width: 230px !important;
  }

  .get-access-title {
    font-size: 64px;
    line-height: 52px;
    margin-top: 30px !important;
  }

  .get-access-content {
    font-size: 35px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 350px) {
  .get-access-invitation {
    width: 28vw;
    height: 10vw;
    font-size: 2.8vw !important;
  }

  .get-access-btn {
    width: 30vw;
    height: 10vw;
    font-size: 3vw !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 887px) {
  .app-content {
    margin-top: 70px !important;
  }
}

@media only screen and (min-width: 888px) and (max-width: 3500px) {
  .app-content {
    margin-top: 60px !important;
  }
}

/*-------login form-------*/
.login-ruleForm {
  background-color: #33454d;
}
.login-ruleForm a {
  color: #10d3c0;
}

.login-ruleForm button {
  color: black;
  margin-top: 15px;
  background-color: #10d3c0 !important;
}

.login-ruleForm .el-checkbox__label,
.login-ruleForm label {
  color: white;
}

.el-form-item.is-required .el-form-item__label:before {
  content: "*";
  color: #ff4949;
  margin-right: 4px;
  opacity: 0;
}

.el-form-item.is-required .el-form-item__label:after {
  content: "*";
  color: #dba87e;
  margin-right: 4px;
}

.el-form-item__error {
  color: #dba87e !important;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.el-radio__input.is-checked .el-radio__inner {
  background-color: #10d3c0 !important;
  border-color: #10d3c0 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #0cc1cb !important;
  border-color: #0cc1cb !important;
}

/*-----register form ----------------*/
.register-ruleForm {
  background-color: #33454d;
}
.register-ruleForm a {
  color: #10d3c0;
}

.register-ruleForm button {
  color: black;
  margin-top: 15px;
  background-color: #10d3c0 !important;
}

.register-ruleForm .el-checkbox__label,
.register-ruleForm label {
  color: white;
}

.el-form-item.is-required .el-form-item__label:before {
  content: "*";
  color: #ff4949;
  margin-right: 4px;
  opacity: 0;
}

.el-form-item.is-required .el-form-item__label:after {
  content: "*";
  color: #10d3c0;
  margin-right: 4px;
}

.el-form-item__error {
  color: #10d3c0 !important;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #0cc1cb !important;
  border-color: #0cc1cb !important;
}

/*-------------*/
.myaccount-login-register {
  padding: 5%;
  background-color: #fff;
}

#navbar-my-account-children {
  height: 0;
  /* transition: height 4s; */
  z-index: 100;
}

#navbar-my-account:hover > #navbar-my-account-children {
  display: block !important;
  height: 150px;
}

/*---------------house info----------------*/
.house-info-left {
  margin-left: 50px;
}

.house-info-cell-black {
  background-color: #000000 !important;
}

.house-info-cell-gray {
  background-color: #262c34 !important;
}

.house-info-cell > button {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); */
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.c-sidenav-item {
  text-decoration: none;
  color: #ccc !important;
}

.c-sidenav-item:hover > div {
  background-color: #333 !important;
  color: #ccc;
}

.c-sidenav-active > div {
  background-color: #444 !important;
  color: white;
}

.c-sidenav-active img.c-sidenav-icon {
  display: none;
}

.c-sidenav-active img.c-sidenav-icon-active {
  display: block !important;
}

@media only screen and (max-width: 576px) {
  .c-sidebar-group-name {
    display: none;
  }
}

.c-sidebar-group-name {
  font-size: 12px;
}

.trans-1 {
  transition: all ease-in-out 0.1s;
}

.el-input.is-disabled .el-input__inner {
  background-color: #fff !important;
  color: black !important;
}

.opacity-0 {
  opacity: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  cursor: pointer;
}

/* ************************ Crypto Button ************************ */
button.coinbase-commerce-button {
  border-radius: 6px;
  background-color: #0667d0;
  background: linear-gradient(#0667d0, #0655ab);
  color: white;
  height: 40px;
  font-size: 14px;
  font-family: Avenir Next, sans-serif;
  font-weight: 500;
  user-select: none;
  -webkit-user-drag: none;
  text-decoration: none;
  cursor: pointer;
}

button.coinbase-commerce-button:hover {
  background: #0666d0;
}
button.coinbase-commerce-button:active {
  background: #0655ab;
}
button.coinbase-commerce-button:focus {
  outline: none;
}
button.coinbase-commerce-button:disabled {
  background: #7d95b6;
  color: rgba(0, 0, 0, 0.4);
  cursor: not-allowed;
}

button.coinbase-commerce-button > span {
  color: white;
  font: normal 500 14px/20px -apple-system, BlinkMacSystemFont,
    ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue",
    "Lucida Grande", sans-serif;
  letter-spacing: 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  white-space: nowrap;
}

div.commerce-loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(6, 103, 208, 0.05);
  border-radius: 100%;
  border-top-color: white;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  33% {
    transform: rotate(90deg);
  }
  66% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

div.coinbase-commerce-iframe-container {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99998;

  background-color: rgba(0, 0, 0, 0.5);
}

iframe.coinbase-commerce-iframe {
  position: fixed;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 0;
  z-index: 99999;
  height: calc(100% - 100px);
  width: 100%;
  border: none;
}

.hover-bg-dark:hover {
  /* background-color: #2e2e2e; */
  border-radius: 5px;
}

.trans-2 {
  transition: all 0.2s ease-in-out;
}

._1_Dg2 {
  padding-top: 100px;
}

.wp-128 {
  width: 128px !important;
}

.btn-disabled {
  background-color: #10d3c0 !important;
  cursor: not-allowed !important;
}
._1BRif::before,
._t1897::before {
  content: none !important;
  display: block;
  position: absolute;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #444;
}
._1BRif {
  position: initial !important;
}
.address-custom p {
  margin-bottom: 0 !important;
}

.language-dropdown:after {
  margin-left: 48% !important;
  border-top: 0.5rem solid !important;
  border-right: 0.5rem solid transparent !important;
  border-left: 0.5rem solid transparent !important;
  vertical-align: 0.02rem !important;
}
.marketplace-dropdown {
  background-color: #00b4c9 !important;
  padding: 2px 15px !important;
}
.marketplace-dropdown:after {
  margin-left: 10% !important;
  border-top: 0.5rem solid #10d3c0 !important;
  border-right: 0.5rem solid transparent !important;
  border-left: 0.5rem solid transparent !important;
  vertical-align: 0.1rem !important;
}

