.gradient-bg {
  background-image: linear-gradient(
    to right,
    RGB(0, 149, 126),
    RGB(3, 255, 164)
  );
}

.card .card-header {
  border-radius: 7px;
}

.fa-caret-down:hover {
  color: #156cab !important;
}
/* Custom Tab Styles */
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000 !important;
  border-bottom: 4px solid #000 !important;
}
.nav-tabs .nav-link {
  border: none !important;
  border-bottom: 4px solid #0dbfcd !important;
}
.nav-link {
  color: #0dbfcd !important;
}

.purchase-btn {
  background-color: #323a45 !important;
  color: #dba87e !important;
}
.myMAP iframe {
  height: 600px;
  width: 100%;
}
